<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import { useAuthStore } from '~/stores/auth.store';
import GenericError from '~/components/user-interface/generic-error.vue';
import { useSiteStore } from '~/stores/site.store';
import RadioButton from 'primevue/radiobutton';
import { radioButtonPassthrough } from '~/components/passthroughs/radiobutton';

const { $t } = useNuxtApp();
const auth = useAuthStore();
const site = useSiteStore();
const { $complianceService } = useServices();
const verificationNames = reactive([]);
const chosenVeriName = ref('');
const loader = ref(false);
const disableContinue = computed(() => {
  return chosenVeriName?.value?.length < 1;
});
const errorMessage = ref('');

onMounted(async () => {
  loader.value = true;
  await $complianceService.getPostRegNames().then((proxyData) => {
    const data = { ...proxyData };
    if (data?.isSuccessful) {
      loader.value = false;
      data.data.forEach((el) => {
        const veriName = (
          el.firstNames +
          ' ' +
          el.lastname
        ).toLocaleLowerCase();
        verificationNames.push(veriName);
      });
    } else {
      loader.value = false;
      auth.setPartialPostReg(true);
      site.activateModal('registrationWelcomeOffer');
    }
  });
});

function goToWelcomeOffer() {
  loader.value = true;
  //make api call if correct close modal
  const verifyName = $complianceService
    .verifyPostRegNameSelection(chosenVeriName.value)
    .then((data) => {
      if (data?.data) {
        auth.setPartialPostReg(false);
        auth.setDocumentVerificationStatus(true);
      } else {
        // check if failed name check set val in state
        auth.setPartialPostReg(true);
      }
      loader.value = false;
      site.activateModal('registrationWelcomeOffer');
    });
}
</script>

<template>
  <LazyUserInterfaceGenericLoader v-if="loader" container />
  <div class="flex flex-col flex-wrap justify-center mx-3">
    <div class="w-full">
      <p class="text-left texxt-base-priority text-sm font-bold my-4">
        {{ $t('to-verify-acc-select-name') }}
      </p>
      <div
        v-for="name in verificationNames"
        :key="name"
        class="flex items-center w-full bg-light-200 dark:bg-dark-700 rounded-md mb-2 text-dark-700 dark:text-white"
      >
        <RadioButton
          v-model="chosenVeriName"
          :inputId="name"
          name="dynamic"
          :value="name"
          class="ml-1"
          :pt="radioButtonPassthrough"
        />
        <label :for="name" class="py-1 capitalize w-full cursor-pointer">
          {{ name }}
        </label>
      </div>
    </div>
  </div>
  <GenericError v-if="errorMessage" class="mb-2" state="danger">
    {{ errorMessage }}
  </GenericError>
  <div class="w-full dark:bg-dark-800 bg-light-200 mt-2 flex justify-center">
    <Button
      :disabled="disableContinue"
      @click="goToWelcomeOffer()"
      class="w-full m-3 justify-center"
    >
      {{ $t('continue') }}
    </Button>
  </div>
</template>

<style scoped lang="scss">
p {
  color: #2b303b;
  .dark & {
    color: #fff;
  }
}
</style>

<style lang="scss">
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: none;
}
</style>
